import React from "react";
import { Link } from "react-router-dom";

const Writing = () => {
  return (
    <div>
      <h2>Writing</h2>
      <p>
        Why write? Writing helps me gain clarity. Thoughts are just thoughts
        swirling around in the brain if not written down. Writing is also
        reflection. It helps me make sense of all my life experiences. When I
        write my autobiography, my future self will thank me for all these
        snippets I've journalled to document my life.{" "}
      </p>
      {/* Add new articles here and in App.js*/}

      <ul>
        {/*
        <li>
          <Link to="/writing/tech-ceo">Dear Manhattan Tech CEO</Link>
        </li>
        */}
        <li>
          <Link to="/writing/argue">How to Argue Well.</Link>
        </li>
        <li>
          <Link to="/writing/overwhelmed">Overwhelmed</Link>
        </li>

        <li>
          <Link to="/writing/eldest-daughter">
            Unlearning How to Be an Eldest Daughter
          </Link>
        </li>
        <li>
          <Link to="/writing/principles">Principles</Link>
        </li>
        <li>
          <Link to="/writing/personal-essay">
            Personal Essay for Harvard Law School
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Writing;
