import React from "react";
import Argue from "../assets/argue.png";

const TemplateArticle = () => {
  return (
    <div>
      <p>September 16, 2024</p>
      <h2>How to Argue Well.</h2>
      <p>
        Instead of attending law school, you can simply understand the following
        well. Example:{" "}
        <i>
          “Law school does not teach you how to practice the law. Therefore, it
          is a waste of time and money.”
        </i>
      </p>

      <div>
        <h2>Level 0: Name calling.</h2>
        <p>
          <i>“You’re ugly, dumb, or both.”</i>
        </p>

        <h2>Level 1: Attack the tone.</h2>
        <p>
          <i>“You’re yelling, calm down.”</i>
        </p>

        <h2>Level 2: Discredit the other person.</h2>
        <p>
          <i>
            “She is a professional golf player, so she knows nothing about law.”
          </i>
        </p>

        <h2>Level 3: Challenge the conclusion.</h2>
        <p>
          <i>
            “Law school does not teach you how to practice the law. But, she
            received a full-scholarship so it is not a waste of money.”
          </i>
        </p>

        <h2>Level Harvard Law School: Attack the premise.</h2>
        <p>
          <i>
            “Correct, law school does not teach you how to practice law. The
            purpose of law school is to teach you how to think like a lawyer.
            Therefore, the time and monetary investment will compound
            exponentially over time.”
          </i>
        </p>
      </div>
    </div>
  );
};

export default TemplateArticle;
