import React from "react";

const Easier = () => {
  return (
    <div>
      <p>September 14, 2024 </p>
      <h2>It's Getting Easier. </h2>

      <p>
        I can't publish this article publically as it contains some unfiltered
        thoughts and mild criticisms. I trust that my thoughts will stay between
        me and this smaller group.
      </p>

      <h2>Harvard Law School or highschool?</h2>
      <p>
        The first year of law school is notoriously hard, and not just
        academically. People are sacrificing time with partners and friends so
        they can spend their precious minutes reading more case law. There are
        whispers of students taking caffeine pills to study until 3 AM and the
        recreational use of cocaine. To put it more bluntly, the 1L environment
        is mildly toxic. I suppose this is to be expected when you take 80
        academic and life overachievers who have been applauded their whole life
        for being smart. Of course, they would tie their identity to their
        grades and accomplishments (not saying I am any better). Truthfully, I
        have much more respect for those law students with children than I do
        for those trying to come out on top of the bell curve. I wasn't stressed
        until others around me proudly announced their stress and lack of sleep
        as a badge of honor. Personally, I don't find the material nearly as
        hard as my undergraduate math classes, especially Cryptography. However,
        I had a classmate question my ability to pass classes when they might be
        better served focusing on their own learning. I have no desire to become
        BFFs with all 80 classmates just because some secretly have ambitions to
        be the President, clerk for the Supreme Court, get the best Big Law job,
        or insert whatever elite and prestigious job title you'd like. Some
        transfer student from Cornell Law ill advised me that "Everything's a
        competition". I really bit my tongue and somehow managed to politely
        informed him that I didn't share his worldview. Like Peter Thiel says
        “Monopoly is the condition of every successful business.” If you've
        really made it, you probably don't have to compete anymore.
      </p>

      <h2>Harvard v. Vanderbilt</h2>

      <p>
        During my second week in law school, I was invited to attend a weekend
        retreat in New Hampshire sponsored by the deep pockets of 20+ law firms.
        This is how I learned that most student organizations are sponsored by
        law firms, hoping to recruit talent from Harvard. There are ~5 social
        events every day hosted by various student organizations. You basically
        don't need to buy groceries because somehow there is always a free
        lunch, dinner, or happy hour event on campus, courtesy of different
        resource offices or clubs. Moreover, there are so many career resources,
        staffed with proactive and competent employees, something that was
        severely lacking at Vanderbilt. Without seeming ungrateful to my
        undergraduate institution, Harvard is by far a tier above Vanderbilt
        when it comes to resources. I guess that's what you get with an
        endowment of $51 billion, larger than the GDP of over 120 nations.
      </p>

      <h2>My physical and mental health</h2>
      <p>
        I fell sick this weekend and I'm hypothesizing that my increased stress
        levels have lowered my immune system. This is unacceptable because there
        is nothing I protect more dearly than my physical and mental health. My
        mental strain came from these persistent but fleeting moments where I
        questioned if I made the right decision to attend HLS. If I should have
        just taken a year off to go full force on my startup. Or if I should
        drop out after one semester, which is not off the table since I'm
        currently fundraising. Luckily, I met another student coming from
        Stanford, and thus pretty disillusioned by elite institutions, who
        shared my same concerns about the insular "law world". She accurately
        characterized the 1L HLS atmosphere as "infantilizing." and talking to
        her definitely felt like a breath of fresh air. Two more things
        preserving my sanity are: 1) treating law school as my 9-5, and 2)
        writing. At least, week 2 is going better than week 1. Luckily, it's
        only going to get easier from here.
      </p>
    </div>
  );
};

export default Easier;
