import React from "react";

const ManhattanTechCEO = () => {
  return (
    <div>
      <p>August 21, 2024</p>
      <p>Dear Manhattan Tech CEO,</p>

      <p>
        Where to even start? Even though we only dated for 3 months, the time
        felt at least double. Ten years apart in age, yet somehow, I felt more
        chemistry with you than with friends who have known me for many years.
        You were someone who understood my nerdy tech jokes and that I could use
        GRE vocab words and corporate jargon without judgement. I could ask an
        endless stream of questions, and you cared enough to try and answer
        them. I still remember that morning in bed when we spent 60 minutes
        researching and dissecting the technical details of the CrowdStrike
        outage going all the way down to the kernel layer.
      </p>

      <p>
        You taught me the phrase "Be your best self." Early in our relationship,
        everytime I heard that phrase, I kind of wanted to run into a wall. But
        now, I’m realizing why you repeat this saying to your employees. Because
        most people wake up and don’t even bother to show up the best they can
        that day. Before I met you, I was usually the one asking others to go
        workout with me and not the other way around. In a world of American
        overconsumption, I felt no judgement when I told you about my one meal a
        day pseudo-veganism. Not only did you accept my eccentricities, but you
        actually pushed me to be even more me. To stay consistent to my workout
        routine, eat more protein, and less sugar. You influenced me to start
        eating those silly protein bars and get plant protein in my smoothies.
      </p>

      <p>
        Boundaries. You taught me how to have boundaries. As someone who wants
        to do everything, and knows no boundaries, this lesson was much needed.
        I saw you guard your work, workout, and social time so diligently using
        colourful Google calendar blocks. And in New York City especially, a
        city of distractions, this was necessary. The lesson on boundaries
        extended beyond just time. I was also able to apply it to people.
      </p>

      <p>
        Taking you off the pedestal. There was much I admired your for, to the
        point where I sometimes forgot you were human too. Like your messy
        apartment and obessesion with overcommunication. You loved to use more
        words and provide excessive detail. Sometimes it was helpful in the
        planning of dates and activities but when it really mattered, your
        overcommunication failed you. Did you ever consider that using more
        words doesn’t actually mean you communicated your intent and that it
        wasn't understood by me, the recipient?
      </p>

      <p>
        Twice, you failed to consider my feelings. Why did you get back together
        with me that day at mini golf? Why did you keep talking to me after we
        parted ways and offer to visit me? You have discipline when it comes to
        everything but me. You won't hurt me a third time. I'm closing this
        chapter for once and for all. I'm moving forward and making better
        decisions to find the life partner and love I so deserve.
      </p>

      <p>-Zinnie</p>
    </div>
  );
};

export default ManhattanTechCEO;
